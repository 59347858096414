<template>
  <router-link
      :to="{
        name: 'Project Single',
        params: {
          projectID: project.id
        }
      }"
      class="project-card"
    >
      <div class="project-card__img">
        <progress-circular v-if="!imageLoaded"/>
        <img
            v-else
            :src="image"
        />
        <slot name="delete-btn" />
      </div>
      <div class="project-card__desc">
        <div class="project-card__tag">
          {{ getProjectTypeLabel(project.type) }}
        </div>
        <div class="project-card__title">
          {{ project.name }}
        </div>
        <div class="project-card__text">
          {{ project.shortDescription }}
        </div>
        <div class="project-card__progressbar">
          <div
              :style="{
                'background': cashProgress.color,
                'width': cashProgress.percent + '%'
              }"
              class="project-card__progressbar__thumb"
          />
          <span
            :class="[
              'project-card__progressbar__percent',
              cashProgress.percent > 50 ? 'project-card__progressbar__percent--white' : ''
            ]"
          >
            {{ parseFloat(cashProgress.percent.toFixed(2)) }}%
          </span>
        </div>
        <div class="project-card__finance">
          <div class="project-card__finance__half">
            <p class="project-card__finance__title">
              {{ $t("common.collected") }}
            </p>
            <p class="project-card__finance__subtitle">
              {{ makeMoneyFormatFromNum(project.currentAmount) }}
            </p>
          </div>
          <div class="project-card__finance__half">
            <p class="project-card__finance__title">
              {{ $t('need') }}
            </p>
            <p class="project-card__finance__subtitle">
              {{ makeMoneyFormatFromNum(project.targetAmount) }}
            </p>
          </div>
        </div>
      </div>
  </router-link>
</template>

<script>

import {calculateCashProgress, getProjectTypeLabel} from "../../utils/projects";
import {makeMoneyFormatFromNum} from "../../utils/dataManipulations";
import {getFileAsBlobUrl} from "../../api/files";
import ProgressCircular from "../ui/ProgressCircular/ProgressCircular";

export default {
  name: "ProjectCard",
  components: {ProgressCircular},
  data: () => ({
    image: null,
    imageLoaded: false
  }),
  props: {
    project: Object,
  },
  computed: {
    cashProgress(){
      return calculateCashProgress(this.project.currentAmount, this.project.targetAmount)
    }
  },
  methods: {
    makeMoneyFormatFromNum,
    getProjectTypeLabel
  },
  async mounted() {
    await getFileAsBlobUrl(this.project.imageId)
        .then(response => {
          this.image = response;
        })
        .catch(error => {
          console.warn(error);
        })
        .then(() => {
          this.imageLoaded = true;
        });
  }
}
</script>

<style lang="scss" src="./ProjectCard.scss"/>