<template>
  <router-link
    :to="{
      name: 'NewsSingle',
      params: {
        id: news.id
      },
    }"
    class="news-card"
  >
    <div class="news-card__img">
      <progress-circular v-if="!imageLoaded" />
      <img v-else :src="image" />
      <slot name="delete-btn" />
    </div>
    <div class="news-card__desc">
      <div class="news-card__title">
        {{ news?.title }}
      </div>
      <div class="news-card__text">
        {{ news?.description }}
      </div>
      <div class="news-card__date">
        <svg data-src="/img/icons/clock.svg" class="news-card__date__icon" />
        {{convertDate(news?.createdDate)}}
      </div>
    </div>
  </router-link>
</template>

<script>
import { getFileAsBlobUrl } from '../../api/files'
import ProgressCircular from '../ui/ProgressCircular/ProgressCircular'
import {convertDate} from "../../utils/dataManipulations";

export default {
  name: 'ProjectCard',
  methods: {convertDate},
  components: { ProgressCircular },
  data: () => ({
    image: null,
    imageLoaded: false,
  }),
  props: {
    news: Object,
  },
  async mounted() {
    await getFileAsBlobUrl(this.news?.mainMediaId)
      .then((response) => {
        this.image = response
      })
      .catch((error) => {
        console.warn(error)
      })
      .then(() => {
        this.imageLoaded = true
      })
  },
}
</script>

<style lang="scss" src="./NewsCard.scss" />
