<template>
  <div>
    <div class="form">
      <div class="form-group form-group--full">
        <div class="form-label">Фон</div>
        <input
          class="form-input"
          type="color"
          v-model="payload.backgroundColor"
        />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Иллюстрация</div>
        <file-input
          v-model="payload.backgroundImageId"
          :handler="uploadFile"
          :accept="ALLOWED_IMAGE_TYPES"
          :loading="uploadMediaRequestPending"
          id="call-to-action_bg"
        />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Заголовок</div>
        <input
          class="form-input"
          type="text"
          v-model="titleWithActiveLang.text"
        />
      </div>

      <div class="form-group form-group--full">
        <div class="form-label">Ссылка для кнопки</div>
        <input
          class="form-input"
          type="text"
          v-model="btnUrlWithActiveLang.text"
        />
      </div>

      <div class="form-group form-group--full">
        <btn
          @click="submit"
          class="u-mt-2"
          color="green"
          height="48px"
          width="200px"
          :loading="requestPending"
        >
          {{ $t('button.save') }}
        </btn>
      </div>
    </div>
  </div>
</template>

<script>
import Btn from '../../components/ui/Btn'
import TextareaInput from '../../components/ui/TextareaInput'
import FileInput from '../ui/FileInput'
import { ALLOWED_IMAGE_TYPES } from '../../constants/file-types'
import { mapState } from 'vuex'
import { uploadFileApi } from '../../api/files'
import { generateLocalesForProperties } from '../../utils/dataManipulations'

export default {
  name: 'CallToActionForm',
  components: {
    Btn,
    TextareaInput,
    FileInput,
  },
  props: {
    handler: {
      type: Function,
      required: true,
    },
    predefinedData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    ALLOWED_IMAGE_TYPES,
    uploadMediaRequestPending: false,
    requestPending: false,
    payload: {
      titles: [],
      descriptions: [],
      buttonTexts: [],
      backgroundColor: null,
      slug: null,
    },
  }),
  computed: {
    ...mapState('lang', ['adminActiveLang']),
    titleWithActiveLang() {
      return this.payload.titles.find(
        (title) => title.locale === this.adminActiveLang,
      )
    },
    descriptionWithActiveLang() {
      return this.payload.descriptions.find(
        (desc) => desc.locale === this.adminActiveLang,
      )
    },
    btnTextWithActiveLang() {
      return this.payload.buttonTexts.find(
        (btnText) => btnText.locale === this.adminActiveLang,
      )
    },
    btnUrlWithActiveLang() {
      return this.payload.buttonUrls.find(
        (btnText) => btnText.locale === this.adminActiveLang,
      )
    },
  },
  methods: {
    submit() {
      this.requestPending = true
      this.$props.handler(this.payload).then(() => {
        this.requestPending = false
      })
    },
    async uploadFile(file) {
      this.uploadMediaRequestPending = true
      return new Promise((resolve, reject) => {
        uploadFileApi(file)
          .then((response) => {
            const newFileID = response.data
            resolve(newFileID)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            this.uploadMediaRequestPending = false
          })
      })
    },
  },
  created() {
    if (this.$props.predefinedData) {
      this.payload = this.$props.predefinedData
    } else {
      this.payload = {
        ...this.payload,
        titles: generateLocalesForProperties({
          text: null,
        }),
        descriptions: generateLocalesForProperties({
          text: null,
        }),
        buttonTexts: generateLocalesForProperties({
          text: null,
        }),
        buttonUrls: generateLocalesForProperties({
          text: null,
        }),
      }
    }
  },
}
</script>
