import request from "../request";

export default {
  resource: "about-us",

  async get() {
    const { data } = await request.get(this.resource);
    return data;
  },

  async update(payload) {
    await request.put(this.resource, payload);
  },

  async create(payload) {
    await request.post(this.resource, payload);
  }
}