<template>
  <div>
    <div class="form">
      <div class="form-group form-group--full">
        <div class="form-label">Заголовок</div>
        <input
          class="form-input"
          v-model="titleWithActiveLang.text"
          type="text"
        />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Описание</div>
        <textarea-input
          class="form-input"
          v-model="descriptionWithActiveLang.text"
        />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Текст для кнопки</div>
        <input
          class="form-input"
          type="text"
          v-model="btnTextWithActiveLang.text"
        />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Ссылка для кнопки</div>
        <input
          class="form-input"
          type="text"
          v-model="btnUrlWithActiveLang.text"
        />
      </div>

      <div class="form-group form-group--full">
        <div class="form-label">Слаг</div>
        <input class="form-input" type="text" v-model="payload.slug" />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Файл</div>
        <file-input
          id="success__cards_file"
          v-model="payload.mediaId"
          :handler="uploadImage"
          :loading="uploadMediaRequestPending"
          :accept="ALLOWED_IMAGE_TYPES"
        />
      </div>

      <div class="form-group form-group--full">
        <btn
          :loading="requestPending"
          @click="submit"
          class="u-mt-2"
          color="green"
          height="48px"
          width="200px"
        >
          {{ $t('button.save') }}
        </btn>
      </div>
    </div>
  </div>
</template>

<script>
import { ALLOWED_IMAGE_TYPES } from '../../constants/file-types'
import FileInput from '../ui/FileInput'
import Btn from '../ui/Btn'
import { mapState } from 'vuex'
import { generateLocalesForProperties } from '../../utils/dataManipulations'
import TextareaInput from '../ui/TextareaInput.vue'
import { uploadFileApi } from '../../api/files'

export default {
  name: 'SuccessCardsForm',
  components: {
    FileInput,
    Btn,
    TextareaInput,
  },
  props: {
    handler: {
      type: Function,
      required: true,
    },
    predefinedData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    ALLOWED_IMAGE_TYPES,
    uploadMediaRequestPending: false,
    requestPending: false,
    payload: {
      slug: '',
      mediaId: null,
    },
  }),

  computed: {
    ...mapState('lang', ['adminActiveLang']),
    titleWithActiveLang() {
      return this.payload.titles.find(
        (title) => title.locale === this.adminActiveLang,
      )
    },
    descriptionWithActiveLang() {
      return this.payload.descriptions.find(
        (desc) => desc.locale === this.adminActiveLang,
      )
    },
    btnTextWithActiveLang() {
      return this.payload.buttonTexts.find(
        (btnText) => btnText.locale === this.adminActiveLang,
      )
    },
    btnUrlWithActiveLang() {
      return this.payload.buttonUrls.find(
        (btnText) => btnText.locale === this.adminActiveLang,
      )
    },
  },
  methods: {
    submit() {
      this.requestPending = true
      this.$props.handler(this.payload).then(() => {
        this.requestPending = false
      })
    },
    async uploadImage(file) {
      this.uploadMediaRequestPending = true
      return new Promise((resolve, reject) => {
        uploadFileApi(file)
          .then((response) => {
            const newFileID = response.data
            resolve(newFileID)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            this.uploadMediaRequestPending = false
          })
      })
    },
  },

  created() {
    this.langForEdit = this.activeLang

    if (this.$props.predefinedData) {
      this.payload = this.$props.predefinedData
    } else {
      this.payload.titles = generateLocalesForProperties({
        text: null,
      })
      this.payload.descriptions = generateLocalesForProperties({
        text: null,
      })
      this.payload.buttonTexts = generateLocalesForProperties({
        text: null,
      }),
      this.payload.buttonUrls = generateLocalesForProperties({
        text: null,
      })
    }
  },
}
</script>
