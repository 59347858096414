<template>
  <div class="admin-block">
    <page-title>
      <template v-slot:title>
        {{ computedTitle }}
      </template>
    </page-title>
  </div>

  <div class="c-card">
    <success-cards-form
      :handler="update"
      :predefinedData="predefinedData"
      v-if="isLoaded"
    />
  </div>
</template>

<script>
import SuccessCardsForm from '../../../components/workspace/SuccessCardsForm.vue'
import PageTitle from '../../../components/Layout/PageTitle'
import SuccessCardsApi from '../../../api/admin/success-cards'
import { mapState } from 'vuex'
import { generateLocalesForProperties } from '../../../utils/dataManipulations'

export default {
  name: 'AdminNewsCreate',
  components: {
    SuccessCardsForm,
    PageTitle,
  },
  data: () => ({
    predefinedData: {},
    isLoaded: false,
  }),
  computed: {
    ...mapState('lang', ['adminActiveLang']),

    computedTitle() {
      const findTitle = this.predefinedData.titles?.find(
        (item) => item.locale === this.adminActiveLang,
      )

      return findTitle ? findTitle.text : 'Карточка'
    },
  },
  methods: {
    async update(payload) {
      return await SuccessCardsApi.update({
        ...payload,
        id: this.$route.params.id,
      }).then(() => {
        this.$toast.success(this.$t('alert.success.updatedData'))

        this.$router.push({
          name: 'Admin Success Cards Index',
        })
      })
    },
  },
  mounted() {
    SuccessCardsApi.get(this.$route.params.id).then((res) => {
      this.predefinedData = {
        slug: res.slug,
        mediaId: res.mediaId,
        titles: generateLocalesForProperties({
          text: null,
        }).map((item) => {
          if (item.locale === this.adminActiveLang) {
            return {
              ...item,
              text: res.title,
            }
          }
          return item
        }),
        descriptions: generateLocalesForProperties({
          text: null,
        }).map((item) => {
          if (item.locale === this.adminActiveLang) {
            return {
              ...item,
              text: res.description,
            }
          }
          return item
        }),
        buttonTexts: generateLocalesForProperties({
          text: null,
        }).map((item) => {
          if (item.locale === this.adminActiveLang) {
            return {
              ...item,
              text: res.buttonText,
            }
          }
          return item
        }),
        buttonUrls: generateLocalesForProperties({
          text: null,
        }).map((item) => {
          if (item.locale === this.adminActiveLang) {
            return {
              ...item,
              text: res.buttonUrl,
            }
          }
          return item
        }),
      }

      this.isLoaded = true
    })
  },
}
</script>
