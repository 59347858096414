<template>
  <div class="admin-block">
    <page-title>
      <template v-slot:title> Cоздание карточка призыва </template>
    </page-title>
  </div>

  <div class="c-card">
    <call-to-action-form :handler="create" />
  </div>
</template>

<script>
import CallToActionForm from '../../../components/workspace/CallToActionForm.vue'
import PageTitle from '../../../components/Layout/PageTitle'
import CallToActionApi from '../../../api/admin/call-to-action'

export default {
  name: 'AdminCallToActionCreate',
  components: {
    CallToActionForm,
    PageTitle,
  },
  methods: {
    async create(payload) {
      return await CallToActionApi.create(payload).then(() => {
        this.$toast.success(this.$t('alert.success.callToActionCreated'))

        this.$router.push({
          name: 'AdminCallToActionIndex',
        })
      })
    },
  },
}
</script>
