import request from "../request";

export default {
  resource: "news",

  async getAll() {
    const { data } = await request.get(this.resource);
    return data;
  },

  async get(id) {
    const { data } = await request.get(this.resource + '/' + id);
    return data;
  },

  async delete(id) {
    await request.delete(this.resource + '/' + id);
  },

  async update(payload) {
    await request.put(this.resource, payload);
  },

  async create(payload) {
    await request.post(this.resource, payload);
  }
}