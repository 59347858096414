<template>
  <div class="footer">
    <div class="footer__container container">
      <div class="footer__info">
        <router-link
          :to="{
            name: 'Home'
          }"
          class="footer__logo"
        >
          <svg data-src="/img/logo-wellt.svg"/>
        </router-link>
        <div class="footer__slogan">
          {{ $t('slogan') }}
        </div>
        <div class="footer__copyright">
          Wellt © {{ currentYear }}
        </div>
      </div>
      <div class="footer__links">
        <div
            v-for="(link, index) in links"
            :key="index"
            class="footer__links__item"
        >
          <router-link
              :to="link.path"
              class="footer__links__item__a"
          >
            {{ link.name }}
          </router-link>
        </div>
      </div>
      <div class="footer__subscribe">
<!--        <p>Номер телефона</p>
        <a href="tel:+998 97 727 28 97">
          <h4>+998 97 727 28 97</h4>
        </a>-->

        <p class="u-mt-3">Электронная почта</p>
        <h4>wellt.publicfunding@yandex.ru</h4>

      </div>

      <div class="footer__socnets">
        <a target="_blank" class="footer__socnets__item" href="https://www.facebook.com/Welltuz-109903545114551">
          <svg data-src="/img/icons/social-networks/facebook.svg"/>
        </a>
        <a target="_blank" class="footer__socnets__item" href="https://t.me/welltuz">
          <svg data-src="/img/icons/social-networks/telegram.svg"/>
        </a>
        <a target="_blank" class="footer__socnets__item" href="https://instagram.com/wellt.uz?r=nametag">
          <svg data-src="/img/icons/social-networks/instagram.svg"/>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
// import Btn from "../ui/Btn";

export default {
  name: "Footer",
  // components: {Btn},
  data: () => ({
    currentYear: new Date().getFullYear()
  }),
  props: {
    links: Array
  }
}
</script>

<style lang="scss" src="./Footer.scss"/>