<template>
  <div class="success">
    <img
        :src="dataIconPath"
        class="success__icon"
    />
    <div class="success__title">
      {{ dataTitle }}
    </div>
    <div class="success__description" v-if="dataDescription">
      {{ dataDescription }}
    </div>
    <btn
        v-if="dataBtnPath"
        :to="dataBtnPath"
        class="success__btn"
        color="grey"
        outlined
    >
      {{ dataBtnText }}
    </btn>
  </div>
</template>

<script>
import Btn from "../ui/Btn/Btn";
import {getSuccessCardBySlugApi} from "@/api/public";
import {getFileAsBlobUrl} from "@/api/files";

export default {
  name: "SuccessBlock",
  components: {Btn},
  props: {
    title: String,
    description: String,
    iconPath: String,
    btnText: String,
    btnPath: [String, Object],
    serverProvider: String,
  },
  data() {
    return {
      dataTitle: this.title,
      dataDescription: this.description,
      dataIconPath: this.dataIconPath,
      dataBtnText: this.btnText,
      dataBtnPath: this.btnPath,
      payload: {},
    }
  },
  mounted() {
    if(this.serverProvider) {
      getSuccessCardBySlugApi(this.serverProvider)
          .then(async (res) => {
            this.payload = res;
            this.dataTitle = this.payload.title;
            this.dataDescription = this.payload.description;
            this.dataIconPath = await getFileAsBlobUrl(this.payload.mediaId);
            this.dataBtnText = this.payload.buttonText;
            this.dataBtnPath = this.payload.buttonUrl;
          })


    }

  }

}
</script>

<style lang="scss" src="./SuccessBlock.scss"/>