<template>
  <div class="admin-block">
    <page-title>
      <template v-slot:title> Cоздание новости </template>
    </page-title>
  </div>

  <div class="c-card">
    <news-form :handler="create" />
  </div>
</template>

<script>
import NewsForm from '../../../components/workspace/NewsForm.vue'
import PageTitle from '../../../components/Layout/PageTitle'
import NewsApi from '../../../api/admin/news'

export default {
  name: 'AdminNewsCreate',
  components: {
    NewsForm,
    PageTitle,
  },
  methods: {
    async create(payload) {
      return await NewsApi.create(payload).then(() => {
        this.$toast.success(
          this.$t('alert.success.newsCreated'),
        )

        this.$router.push({
          name: 'Admin News Index',
        })
      })
    },
  },
}
</script>
