import request from "./request";

export const getRegionsApi = async () => {
    const {data} = await request.get('public/regions');
    return data;
}
export const getCitiesApi = async (regionID) => {
    const {data} = await request.get('public/cities/' + regionID);
    return data;
}
