import request from "./request";
import store from "../store";


const RESOURCE = 'card';
const PUBLIC_RESOURCE = () => {
  const user = store.state.auth.user;
  console.log(user);
  return `${!user ? 'public/' : ''}${RESOURCE}`;
}

export const getBankCardsOfUserApi = async () => {
    const {data} = await request.get(RESOURCE);
    return data;
}

export const removeBankCardOfUserApi = async (id) => {
    await request.delete(`${PUBLIC_RESOURCE()}/${id}`);
}

export const addCardToUserApi = (DTO) => {
    const url = `${PUBLIC_RESOURCE()}`;
    return new Promise((resolve, reject) => {
        request.post(url, DTO).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        });
    });
}
export async function updateCardApi(id, payload){
    return await request.put(`${PUBLIC_RESOURCE()}/${id}`, null, {
        params: payload
    })
}
export const sendConfirmationCodeForBankCardApi = (cardId) => {
    const url = `${PUBLIC_RESOURCE()}/send-verification-code`;

    return new Promise((resolve, reject) => {
        request.put(url, {cardId}).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        });
    });
}
export const confirmCardToUserApi = (code, cardId) => {
    const url = `${PUBLIC_RESOURCE()}/verify`;

    return new Promise((resolve, reject) => {
        request.put(url, {
            code, cardId
        }).then(response => {
            resolve(response)
        }).catch(error => {
            reject(error)
        });
    });
}