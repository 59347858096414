export const PROJECT_STATUS_MODERATING = "MODERATING";
export const PROJECT_STATUS_MODERATION_CANCELED = "MODERATION_CANCELED";
export const PROJECT_STATUS_CANCELED = "CANCELED";
export const PROJECT_STATUS_REFUND = "REFUND";
export const PROJECT_STATUS_INVESTED = "INVESTED";
export const PROJECT_STATUS_FUNDING = "FUNDING";
export const PROJECT_STATUS_CREATED = "CREATED";
export const PROJECT_STATUS_FINISHED = "FINISHED";

export const PROJECT_STATUS_INVESTED_COLOR = "green";
export const PROJECT_STATUS_MODERATION_CANCELED_COLOR = "red";
export const PROJECT_STATUS_MODERATING_COLOR = "lightblue";
export const PROJECT_STATUS_CANCELED_COLOR = "red";
export const PROJECT_STATUS_REFUND_COLOR = "black";
export const PROJECT_STATUS_FUNDING_COLOR = "lightgreen";
export const PROJECT_STATUS_CREATED_COLOR = "aqua";
export const PROJECT_STATUS_FINISHED_COLOR = "green";


export const PROJECT_STATUSES_WHEN_EDITING_ENABLED = [PROJECT_STATUS_CANCELED, PROJECT_STATUS_MODERATION_CANCELED];
export const PROJECT_STATUSES_WHEN_DELETING_ENABLED = [PROJECT_STATUS_CANCELED, PROJECT_STATUS_CREATED];
