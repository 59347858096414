import axios from 'axios';
import {deleteCookie, getCookie} from "../utils/cookie";
import store from '../store'


const request = axios.create({
    baseURL: process.env.VUE_APP_SERVER_URL
});

request.interceptors.request.use((config) => {
    // Do something before request is sent

    let authTokenCookie = getCookie('authToken');
    if (authTokenCookie) config.headers["Authorization"] = ('Bearer ' + getCookie('authToken'));
    config.headers["Accept"] = 'application/json';
    config.headers["Accept-Language"] = store.state.lang.activeLang;

    return config;
}, null);

request.interceptors.response.use(
    null,
    error => {
        console.warn("Request error data", error.response.data);
        console.warn("Request status", error.response.status);

        if (error.response && error.response.status === 401) {
            deleteCookie('authToken');

        }
        if (error.response && error.response.status === 500 || error.response.status === 503 || error.response.status === 502) {
            alert('Непредвиденная ошибка на сервере, пожалуйста попробуйте позже')
        }

        return Promise.reject(error.response)
    }
)

export default request;