<template>
  <div class="news-single">
    <div class="container news-single__container">
      <alert v-if="errorLoad" type="error">
        {{ errorMessage }}
      </alert>

      <progress-linear v-if="!isLoaded" />

      <div class="news-single__wrapper">
        <div class="news-single__date">
          <svg
              data-src="/img/icons/clock.svg"
              class="news-single__date__icon"
          />
          {{ convertDate(data.createdDate) }}
        </div>
        <div class="news-single__top">
          <h3 class="news-single__title">{{ data.title }}</h3>
          <p class="news-single__description">{{ data.description }}</p>

          <div class="news-single__media" v-if="mainMediaContent">
            <img
                :src="mainMediaContent"
                alt="news-img"
                class="news-single__media__img"
            />
          </div>
          <wysiwyg-viewer v-html="data.content"/>
        </div>
      </div>
    </div>
    <news-slider
        :title="$t('title.otherNews')"
    />
  </div>


</template>

<script>
import NewsApi from '../../api/news'
import ProgressLinear from '../../components/ui/ProgressLinear/ProgressLinear'
import { getFileAsBlobUrl } from '../../api/files';
import {
  DEFAULT_ACTIVE_PAGE,
  DEFAULT_ITEMS_PER_PAGE,
} from '../../constants/pagination'
import Alert from '../../components/ui/Alert/Alert'
import NewsSlider from '../../components/NewsSlider.vue'
import WysiwygViewer from "@/components/WysiwygViewer.vue";
import {convertDate} from "../../utils/dataManipulations";

export default {
  name: 'PublicNewsSingle',
  components: {
    WysiwygViewer,
    ProgressLinear,
    Alert,
    NewsSlider
  },
  data: () => ({
    data: {},
    mainMediaContent: null,
    isLoaded: false,
    news: [],
    errorLoad: false,
    errorMessage: '',
    newsLoaded: false,
    flickityOptions: {
      cellAlign: 'left',
      contain: true,
      imagesLoaded: true,
      prevNextButtons: true,
      pageDots: true,
      lazyLoad: true,
      arrowShape: {
        x0: 10,
        x1: 60,
        y1: 50,
        x2: 65,
        y2: 45,
        x3: 20,
      },
    },
  }),
  computed: {
    breadCrumbs() {
      return [
        {
          text: this.$t('nav.home'),
          disabled: false,
          href: { name: 'Home' },
        },
        {
          text: this.$t('nav.news'),
          disabled: false,
          href: { name: 'NewsIndex' },
        },
        {
          text: this.data?.title,
          disabled: true,
          href: '',
        },
      ]
    },
  },
  methods: {
    convertDate,
    scrollToTop() {
      document.querySelector('.header').scrollIntoView({
        behavior: 'smooth',
      })
    },
    init(flickity) {
      if (flickity) {
        flickity.on('dragStart', () =>
          flickity.slider.childNodes.forEach(
            (slide) => (slide.style.pointerEvents = 'none'),
          ),
        )
        flickity.on('dragEnd', () =>
          flickity.slider.childNodes.forEach(
            (slide) => (slide.style.pointerEvents = 'all'),
          ),
        )
      }
    },
    getSingle() {
      NewsApi.getSingle(this.$route.params.id)
        .then(async (res) => {
          await getFileAsBlobUrl(res.data?.mainMediaId).then((response) => {
            this.mainMediaContent = response
          })

          this.data = res.data
        })
        .catch((error) => {
          if (error.status === 404) {
            this.errorMessage = this.$t('alert.error.newsNotFound')
          } else {
            this.errorMessage = this.$t(
              'alert.error.unexpectedError.loadingProject',
            )
          }

          this.errorLoad = true
        })
        .finally(() => {
          this.isLoaded = true
          this.scrollToTop()
        })
    },
    getLatestNews() {
      NewsApi.getNews({
        size: DEFAULT_ITEMS_PER_PAGE,
        page: DEFAULT_ACTIVE_PAGE,
      }).then((res) => {
        this.news = res.data.content
        this.newsLoaded = true
      })
    },
  },
  mounted() {
    this.getSingle()
    this.getLatestNews()
  },

  watch: {
    '$route.params.id': {
      handler: function () {
        this.getSingle()
      },
    },
  },
}
</script>

<style lang="scss">
.news-single {
  background-color: #fff;
  &__date {
    font-size: 14px;
    line-height: 22px;
    color: #565e5e;
    margin-bottom: 15px;
    display: flex;
    align-items: center;

    &__icon {
      width: 16px;
      height: 16px;
      margin-right: 8px;
    }
  }
  &__container {
    max-width: 700px;
    padding: 40px 0;
    @include max-sm {
      padding: 30px 15px;
    }
  }
  .news-single__wrapper {
    @include max-xs {
      padding: 0 10px;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 16px;
    color: #000;
  }

  &__description {
    font-size: 14px;
    line-height: 22px;
    color: #565e5e;
    margin-bottom: 24px;
  }

  &__media {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 30px 0;
    &__img {
      width: 100%;
      height: auto;
    }
  }

  &__main {
    &__title {
      font-weight: bold;
      font-size: 24px;
      line-height: 29px;
      margin-bottom: 16px;
      color: #000;
    }

    &__content {
      font-size: 16px;
      line-height: 26px;
      color: #151717;
      p {
        margin: 10px 0;
      }
    }
  }
}
</style>
