<template>
  <div class="about">
    <div class="container">
      <bread-crumbs :links="breadCrumbs"/>

      <div class="about__title">{{ $t('about.title') }}</div>
      <div class="about__what-is">
        <div class="what-is__imgs about__what-is__imgs">
          <img
              alt=""
              class="what-is__img about__what-is__img"
              src="/img/whatis1.png"
          />
          <img
              alt=""
              class="what-is__img about__what-is__img"
              src="/img/whatis2.png"
          />
        </div>
        <div class="what-is__desc about__what-is__desc">
          <p class="about__what-is__text" v-html="$t('about.subtitle')"/>
          <p class="about__what-is__text" v-html="$t('about.text1')"/>
          <p class="about__what-is__text"  v-html="$t('about.text2')"/>
        </div>
      </div>
      <div class="plain-content u-mb-5">
        <h2>
          {{ $t('howItWorks.title') }}
        </h2>
        <p v-html="$t('howItWorks.text')"/>

        <h2>
          {{ $t('requirementsForUser.title') }}
        </h2>
        <ul>
          <li>
            {{ $t('requirementsForUser.list1') }}
          </li>
          <li>
            {{ $t('requirementsForUser.list2') }}
          </li>
          <li>
            {{ $t('requirementsForUser.list3') }}
          </li>
          <li>
            {{ $t('requirementsForUser.list4') }}
          </li>
        </ul>

        <h2>
          {{ $t('requirementsForProject.title') }}
        </h2>
        <p>
          {{ $t('requirementsForProject.text') }}
        </p>
        <ul>
          <li>
            {{ $t('requirementsForProject.list1') }}
          </li>
          <li>
            {{ $t('requirementsForProject.list2') }}
          </li>
          <li>
            {{ $t('requirementsForProject.list3') }}
          </li>
          <li>
            {{ $t('requirementsForProject.list4') }}
          </li>
        </ul>
      </div>

    </div>
  </div>
</template>

<script>
import BreadCrumbs from "../components/ui/BreadCrumbs";

export default {
  name: "About",
  components: {BreadCrumbs},
  computed: {
    breadCrumbs() {
      return [
        {
          text: this.$t('nav.home'),
          disabled: false,
          href: {name: "Home"}
        },
        {
          text: this.$t('nav.about'),
          disabled: true,
          href: ''
        }
      ]
    }
  }
}
</script>