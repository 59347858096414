<template>
  <div class="progress-bar">
    <div class="progress-bar-value"></div>
  </div>
</template>

<script>
export default {
  name: "ProgressLinear"
}
</script>

<style lang="scss" src="./ProgressLinear.scss"/>