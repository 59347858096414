<template>
  <div class="projects-page">
    <div class="container">
      <bread-crumbs :links="breadCrumbs" />

      <progress-linear v-if="!loaded" />

      <template v-else>
        <page-title>
          <template v-slot:title>
            {{ $t('nav.news') }}
          </template>
        </page-title>

        <div class="projects-info">
          <div class="projects-info__total">
            {{ $t('title.foundNews') }}:
            <span class="projects-info__total__highlighted">{{
              totalElements
            }}</span>
          </div>
          <div class="projects-info__sort">
            <span class="projects-info__sort__label">
              {{ $t('title.sort') }}
            </span>
            <custom-select
              v-model="search.sortOrder"
              :items="sortTypes"
              class="projects-info__sort__input"
              icon="/img/icons/sort.svg"
              outlined
              @update:modelValue="getNews()"
            />
          </div>
        </div>
        <progress-linear
          :class="[
            'projects__load-animation',
            {
              'projects__load-animation--active': loading,
            },
          ]"
        />
        <div
          :class="[
            'projects',
            {
              'projects--loading': loading,
            },
          ]"
        >
          <alert v-if="news.length === 0" class="u-mb-2" type="warning">
            {{ $t('alert.warning.notFoundByCriteria') }}
          </alert>
          <template v-if="news.length > 0">
            <div class="projects__items">
              <news-card v-for="item in news" :key="item.id" :news="item" />
            </div>
            <pagination
              v-model="search.page"
              :total-pages="totalPages"
              @update:modelValue="getNews()"
            />
          </template>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from '../../components/ui/BreadCrumbs'
import CustomSelect from '../../components/ui/CustomSelect'
import NewsCard from '../../components/NewsCard'
import ProgressLinear from '../../components/ui/ProgressLinear/ProgressLinear'
import PageTitle from '../../components/Layout/PageTitle'
import Pagination from '../../components/ui/Pagination/Pagination'
import {
  DEFAULT_ACTIVE_PAGE,
  DEFAULT_ITEMS_PER_PAGE,
} from '../../constants/pagination'
import { SORT_ASCENDING, SORT_DESCENDING } from '../../constants/sort-types'
import Alert from '../../components/ui/Alert/Alert'
import { mapState } from 'vuex'
import NewsApi from "../../api/news"

export default {
  name: 'Projects',
  components: {
    Alert,
    Pagination,
    PageTitle,
    ProgressLinear,
    NewsCard,
    CustomSelect,
    BreadCrumbs,
  },
  data: () => ({
    loaded: false,
    totalPages: 0,
    totalElements: 0,

    search: {
      sortOrder: SORT_DESCENDING,
      page: DEFAULT_ACTIVE_PAGE,
      size: DEFAULT_ITEMS_PER_PAGE,
      search: null,
    },

    news: [],
    loading: true,
  }),
  computed: {
    ...mapState('auth', ['globalProjectSearch']),

    supertotalElementsCountFromAllTypes() {
      return this.projectTypes.reduce((prev, next) => {
        prev += next.count
        return prev
      }, 0)
    },
    sortTypes() {
      return [
        {
          value: SORT_DESCENDING,
          label: this.$t('sort.desc'),
        },
        {
          value: SORT_ASCENDING,
          label: this.$t('sort.asc'),
        },
      ]
    },
    breadCrumbs() {
      return [
        {
          text: this.$t('nav.home'),
          disabled: false,
          href: {
            name: 'Home',
          },
        },
        {
          text: this.$t('nav.news'),
          disabled: true,
          href: '',
        },
      ]
    },
  },
  methods: {
    async getNews() {
      console.log(this.search.search)
      this.loading = true
      await NewsApi.getNews(this.search)
        .then((response) => {
          this.news = response.data.content
          this.totalPages = response.data.totalPages
          this.totalElements = response.data.totalElements
        })
        .catch((error) => {
          console.warn(error)
          this.$toast.error(this.$t('alert.error.unexpectedError.default'))
        })
        .then(() => {
          this.loading = false
        })
    },
    selectProjectType(type) {
      this.search.type = type

      this.getNews()
    },
  },
  watch: {
    async globalProjectSearch(val) {
      this.search.search = val
      await this.getNews()
    },
  },
  async created() {
    Promise.all([
      await this.getNews(),
    ])
      .catch((error) => {
        console.warn(error)
        this.$toast.error(this.$t('alert.error.unexpectedError.default'))
      })
      .then(() => {
        this.loading = false
      })

    this.loaded = true
  },
}
</script>
