<template>
  <div class="admin-block">
    <page-title>
      <template v-slot:title> Основной Баннер </template>
    </page-title>
  </div>

  <div class="c-card" v-if="isLoaded">
    <div class="form">
      <toggler
          v-model="payload.backgroundType"
          @update:modelValue="clearBackground"
          :items="['Цвет на фон', 'Картинка на фон']"
          class="u-mb-2"
      />
      <div class="form-group form-group--full" v-if="payload.backgroundType === 'Цвет на фон'">
        <div class="form-label">Фон (цвет)</div>
        <input
          class="form-input"
          type="color"
          v-model="payload.backgroundColor"
        />
      </div>
      <div class="form-group form-group--full" v-if="payload.backgroundType === 'Картинка на фон'">
        <div class="form-label">Картинка для фона</div>
        <file-input
            v-model="payload.backgroundImageId"
            :handler="uploadFile"
            :accept="ALLOWED_IMAGE_TYPES"
            :loading="uploadMediaRequestPending"
            id="admin_slider_bg"
        />
      </div>
<!--      <div class="form-group form-group&#45;&#45;full">
        <div class="form-label">Видео</div>
        <file-input
            v-model="payload.videoId"
            :handler="(file) => uploadFile(file, 'video')"
            :accept="ALLOWED_VIDEO_TYPES"
            :loading="uploadMediaRequestPending"
            id="videoId"
        />
      </div>
      <div class="form-group form-group&#45;&#45;full">
        <div class="form-label">Постер</div>
        <file-input
            v-model="payload.videoPosterId"
            :handler="uploadFile"
            :accept="ALLOWED_IMAGE_TYPES"
            :loading="uploadMediaRequestPending"
            id="videoPosterId"
        />
      </div>-->
      <div class="form-group form-group--full">
        <div class="form-label">Заголовок</div>
        <input
          class="form-input"
          type="text"
          v-model="titleWithActiveLang.text"
        />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Описание</div>
        <TextareInput
          class="form-input"
          v-model="descriptionWithActiveLang.text"
        />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Текст для кнопки</div>
        <input
          class="form-input"
          type="text"
          v-model="btnTextWithActiveLang.text"
        />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Ссылка для кнопки</div>
        <input
          class="form-input"
          type="text"
          v-model="btnUrlWithActiveLang.text"
        />
      </div>

      <div class="form-group form-group--full">
        <btn
          @click="submit"
          class="u-mt-2"
          color="green"
          height="48px"
          width="200px"
        >
          {{ $t('button.save') }}
        </btn>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '../../../components/Layout/PageTitle'
import FileInput from '../../../components/ui/FileInput'
import TextareInput from '../../../components/ui/TextareaInput'
import Btn from '../../../components/ui/Btn'
import MainSliderApi from '../../../api/admin/main-slider'
import { generateLocalesForProperties } from '../../../utils/dataManipulations'
import { uploadFileApi } from '../../../api/files'
import {ALLOWED_IMAGE_TYPES, ALLOWED_VIDEO_TYPES} from '../../../constants/file-types'
import { mapState } from 'vuex'
import Checkbox from "@/components/ui/Checkbox.vue";
import Toggler from "@/components/ui/Toggler/Toggler.vue";

export default {
  name: 'AdminSlider',
  components: {
    Toggler,
    Checkbox,
    PageTitle,
    FileInput,
    TextareInput,
    Btn,
  },

  data: () => ({
    ALLOWED_IMAGE_TYPES,
    ALLOWED_VIDEO_TYPES,
    uploadMediaRequestPending: false,
    isCreated: false,
    isLoaded: false,
    payload: {
      backgroundType: 'Цвет на фон',
      titles: [],
      descriptions: [],
      buttonTexts: [],
      backgroundColor: null,
      backgroundImageId: null,
      videoPosterId: null,
      videoId: null
    },
  }),
  computed: {
    ...mapState('lang', ['adminActiveLang']),
    titleWithActiveLang() {
      return this.payload.titles.find(
        (title) => title.locale === this.adminActiveLang,
      )
    },
    descriptionWithActiveLang() {
      return this.payload.descriptions.find(
        (desc) => desc.locale === this.adminActiveLang,
      )
    },
    btnTextWithActiveLang() {
      return this.payload.buttonTexts.find(
        (btnText) => btnText.locale === this.adminActiveLang,
      )
    },
    btnUrlWithActiveLang() {
      return this.payload.buttonUrls.find(
        (btnText) => btnText.locale === this.adminActiveLang,
      )
    },
  },
  methods: {
    clearBackground() {
      if(this.payload.backgroundType === 'Цвет на фон') {
        this.payload.backgroundImageId = null
      } else {
        this.payload.backgroundColor = null
      }
    },
    submit() {
      if (this.isCreated) {
        MainSliderApi.update(this.payload).then(() => {
          this.$toast.success(this.$t('alert.success.updatedData'))
        })
      } else {
        MainSliderApi.create(this.payload).then(() => {
          this.$toast.success(this.$t('alert.success.updatedData'))
        })
      }
    },

    async uploadFile(file, type) {
      this.uploadMediaRequestPending = true
      return new Promise((resolve, reject) => {
        uploadFileApi(file, null, type)
          .then((response) => {
            const newFileID = response.data
            resolve(newFileID)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            this.uploadMediaRequestPending = false
          })
      })
    },
  },
  created() {
    this.payload.buttonTexts = generateLocalesForProperties({
      text: null,
    })
    this.payload.titles = generateLocalesForProperties({
      text: null,
    })
    this.payload.descriptions = generateLocalesForProperties({
      text: null,
    })
    this.payload.buttonUrls = generateLocalesForProperties({
      text: null,
    })
  },
  mounted() {
    MainSliderApi.get().then((res) => {
      if (res.createdDate) {
        this.isCreated = true
      }

      this.payload = {
        ...this.payload,
        backgroundColor: res.backgroundColor,
        backgroundImageId: res.backgroundImageId,
        buttonUrl: res.buttonUrl,
        videoPosterId: res.videoPosterId,
        videoId: res.videoId,
      }

      if (Array.isArray(res.titles) && res.titles.length) {
        this.payload.titles = res.titles
      }
      if (Array.isArray(res.descriptions) && res.descriptions.length) {
        this.payload.descriptions = res.descriptions
      }
      if (Array.isArray(res.buttonTexts) && res.buttonTexts.length) {
        this.payload.buttonTexts = res.buttonTexts
      }

      if (Array.isArray(res.buttonUrls) && res.buttonUrls.length) {
        this.payload.buttonUrls = res.buttonUrls
      }
      this.isLoaded = true
    })
  },
}
</script>
