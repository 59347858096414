import {mapGetters} from 'vuex';

export default {
    computed: {
        ...mapGetters('auth', ['userIsAuthenticated'])
    },
    created() {
        if(!this.userIsAuthenticated){
            this.$router.push({
                name: 'Sign In'
            })
        }
    },
}