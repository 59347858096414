<template>
  <div>
    <div class="form">
      <div class="form-group form-group--full">
        <div class="form-label">Заголовок</div>
        <input
          class="form-input"
          type="text"
          v-model="titleWithActiveLang.text"
        />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Краткое описание</div>
        <input
          class="form-input"
          type="text"
          v-model="descriptionWithActiveLang.text"
        />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Контент</div>
        <text-editor ref="TextEditor" v-model="contentWithActiveLang.text" />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Слаг</div>
        <input class="form-input" type="text" v-model="payload.slug" />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Основной медиа</div>
        <file-input
          v-model="payload.mainMediaId"
          :handler="uploadFile"
          id="news_main_media"
          :accept="ALLOWED_IMAGE_TYPES"
          :loading="uploadMediaRequestPending"
        />
      </div>
      <!-- <div class="form-group form-group--full">
        <div class="form-label">Видео постер</div>
        <file-input
          :handler="uploadFile"
          v-model="payload.videoPosterId"
          id="news_video"
          accept="video/mp4,video/x-m4v,video/*"
        />
      </div> -->

      <div class="form-group form-group--full">
        <btn
          :loading="requestPending"
          class="u-mt-2"
          color="green"
          height="48px"
          width="200px"
          @click="submit()"
        >
          {{ $t('button.save') }}
        </btn>
      </div>
    </div>
  </div>
</template>

<script>
import TextEditor from '../ui/TextEditor/TextEditor.vue'
import FileInput from '../ui/FileInput'
import Btn from '../ui/Btn'
import { generateLocalesForProperties } from '../../utils/dataManipulations'
import { mapState } from 'vuex'
import { uploadFileApi } from '../../api/files'
import { ALLOWED_IMAGE_TYPES } from '../../constants/file-types'

export default {
  name: 'NewsForm',
  components: {
    TextEditor,
    FileInput,
    Btn,
  },
  props: {
    handler: {
      type: Function,
      required: true,
    },
    predefinedData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    ALLOWED_IMAGE_TYPES,
    requestPending: false,
    uploadMediaRequestPending: false,
    payload: {
      mainMediaId: null,
      videoPosterId: null,
      slug: null,
    },
  }),
  computed: {
    ...mapState('lang', ['adminActiveLang']),
    titleWithActiveLang() {
      return this.payload.titles.find(
        (title) => title.locale === this.adminActiveLang,
      )
    },
    descriptionWithActiveLang() {
      return this.payload.descriptions.find(
        (desc) => desc.locale === this.adminActiveLang,
      )
    },
    contentWithActiveLang() {
      return this.payload.contents.find(
        (content) => content.locale === this.adminActiveLang,
      )
    },
  },
  methods: {
    submit() {
      this.requestPending = true
      this.$props.handler(this.payload).then(() => {
        this.requestPending = false
      })
    },
    async uploadFile(file) {
      this.uploadMediaRequestPending = true
      return new Promise((resolve, reject) => {
        uploadFileApi(file)
          .then((response) => {
            const newFileID = response.data
            resolve(newFileID)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            this.uploadMediaRequestPending = false
          })
      })
    },
  },
  created() {
    if (this.$props.predefinedData) {
      this.payload = this.$props.predefinedData
    } else {
      this.payload = {
        ...this.payload,
        titles: generateLocalesForProperties({
          text: null,
        }),
        descriptions: generateLocalesForProperties({
          text: null,
        }),
        contents: generateLocalesForProperties({
          text: null,
        }),
      }
    }
  },
  watch: {
    adminActiveLang() {
      this.$refs.TextEditor.updateValue(this.contentWithActiveLang.text)
    },
  },
}
</script>
