<template>
  <div class="projects-slider news-slider">
    <div class="projects-slider__container container">
      <div class="projects-slider__header">
        <h2 class="projects-slider__title">
          {{ title ?? $t('title.news') }}
        </h2>
        <div class="projects-slider__btns">
          <btn
              :to="{
                name: 'NewsIndex'
              }"
              class="projects-slider__all-projects"
              color="grey"
              outlined
          >
            {{ $t('button.seeAll', {msg: $t('common.news')}) }}
          </btn>
        </div>
      </div>

      <div class="projects-slider__body">
        <flickity
            v-if="newsLoaded"
            ref="flickity"
            :options="flickityOptions"
            class="projects-slider__flickity flickity"
            @init="init"
        >
          <news-card
              v-for="(item, index) in news"
              :key="index"
              :news="item"
              class="carousel-cell"
          />
        </flickity>
      </div>
    </div>
  </div>
</template>

<script>
import NewsApi from '@/api/news';
import Flickity from 'vue-flickity'
import NewsCard from '@/components/NewsCard'
import {DEFAULT_ACTIVE_PAGE, DEFAULT_ITEMS_PER_PAGE} from "@/constants/pagination";
import Btn from "@/components/ui/Btn/Btn.vue";
export default {
  name: 'NewsSlider',
  components: {
    Btn,
    Flickity,
    NewsCard,
  },
  props: {
    title: {
      type: String,
    },
  },
  data: () => ({
    newsLoaded: false,
    news: [],
    flickityOptions: {
      cellAlign: 'left',
      contain: true,
      imagesLoaded: true,
      prevNextButtons: true,
      pageDots: true,
      lazyLoad: true,
      arrowShape: {
        x0: 10,
        x1: 60,
        y1: 50,
        x2: 65,
        y2: 45,
        x3: 20,
      },
    },
  }),
  methods: {
    init(flickity) {
      if (flickity) {
        flickity.on('dragStart', () => flickity.slider.childNodes.forEach(slide => slide.style.pointerEvents = "none"));
        flickity.on('dragEnd', () => flickity.slider.childNodes.forEach(slide => slide.style.pointerEvents = "all"));
      }
    },
    getLatestNews() {
      NewsApi.getNews({
        size: DEFAULT_ITEMS_PER_PAGE,
        page: DEFAULT_ACTIVE_PAGE,
      }).then((res) => {
        this.news = res.data.content
        this.newsLoaded = true
      })
    },
  },
  mounted() {
    this.getLatestNews()
  },
}
</script>
<style>
.news-slider {
  background-color: #eee;
}
</style>