<template>
  <component
      :is="checkSlider('slider')"
      ref="flickity"
      :options="flickityOptions"
      class="slider flickity"
  >
    <div class="carousel-cell">
      <div
          :class="[
            'container',
            'slider__container',
            {
              'slider--dark': slider.bg
            }
          ]"
      >
        <div
            :style="{
                'background': `linear-gradient(0deg,
                  ${mainSlider.bgColor},
                  ${mainSlider.bgColor})`
                }"
            class="slider__bg"
        >
          <img
              v-show="mainSlider.bgFile"
              :src="mainSlider.bgFile"
              alt=""
          />
        </div>
        <div class="slider__desc">
          <h1 class="slider__title">{{ mainSlider.title }}</h1>
          <p class="slider__subtitle">
            {{ mainSlider.description }}
          </p>
          <div class="slider__btns">
            <btn
                :to="mainSlider.buttonUrl"
                class="slider__btn"
                color="green"
            >
              {{ mainSlider.buttonText }}
            </btn>
          </div>
        </div>
        <div class="slider__pics">
          <div class="slider__pic slider__pic-origin">
            <!--            <img-->
            <!--                :src="slider.image"-->
            <!--                alt=""-->
            <!--            />-->
            <video
                autoplay
                muted
                loop
                playsinline
                @mouseover="toggleVideoControls"
                @mouseleave="toggleVideoControls"
            >
              <source src="/video/Wellt.MP4">
            </video>
          </div>
          <div class="slider__pic slider__pic-blured">
            <img
                :src="mainSlider.videoPosterFile"
                alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </component>
  <div class="projects-slider">
    <div class="projects-slider__container container">
      <div class="projects-slider__header">
        <h2 class="projects-slider__title">
          {{ $t('title.recentlyProjects') }}
        </h2>
        <div class="projects-slider__btns">
          <btn
              :to="{
                name: 'Project Index'
              }"
              class="projects-slider__all-projects"
              color="grey"
              outlined
          >
            {{ $t('button.seeAll', {msg: $t('common.projects')}) }}
          </btn>
        </div>
      </div>
      <div
          v-if="recentProjectsLoaded"
          class="projects-slider__body"
      >
        <flickity
            v-if="loaded"
            ref="flickity"
            :options="flickityOptions"
            class="projects-slider__flickity flickity"
            @init="init"
        >
          <project-card
              v-for="(item, index) in recentProjects"
              :key="index"
              :project="item"
              class="carousel-cell"
          />
        </flickity>
      </div>
    </div>
  </div>
  <div class="call-to-action">
    <div class="container call-to-action__container">
      <div class="call-to-action__row">
        <router-link
            class="call-to-action__item"
            v-for="(item) in callToActionItems"
            :key="item.title"
            :to="item.buttonUrl"
            :style="{
              '--text-color': item.textColor,
              '--background-color': item.backgroundColor,
            }"
        >
          <h3 class="call-to-action__item__title">
            {{ item.title }}
          </h3>
          <p class="call-to-action__item__description">
            {{ item.description }}
          </p>
          <img class="call-to-action__item__img" :src="item.imageFile" />
        </router-link>
      </div>
    </div>
  </div>
  <div class="what-is">
    <div class="what-is__content">
      <h2 class="what-is__title">
        {{ aboutUs.title }}
      </h2>
      <p class="what-is__text">
        {{ aboutUs.description }}
      </p>
      <btn
          :to="aboutUs.buttonUrl"
          class="what-is__btn"
          color="green"
      >
        {{ aboutUs.buttonText }}
      </btn>
    </div>
    <img class="what-is__image" src="/img/wellt-is.png">
  </div>
  <news-slider/>
  <div class="project-search">
    <img
        alt=""
        class="project-search__bg"
        src="/img/search-project-bg.png"
    />
    <h2 class="project-search__title">
      {{ $t("title.searchByProjects") }}
    </h2>
    <div class="project-search__searchbox">
      <svg data-src="/img/icons/search.svg"/>
      <input
          v-model="searchProjectName"
          :placeholder="`${$t('searchByTitleDesc')}...`"
          class="project-search__searchbox__input"
          type="search"
          @keyup.enter="
              scrollToTop();
              $router.push({
                name: 'Project Index',
                query: {
                  search: searchProjectName
                }
              })
            "
      />
    </div>
    <div class="project-search__examples">
      <p class="project-search__examples__label">{{ $t('example') }}: </p>
      <router-link
          v-for="projectType in PROJECT_TYPES_WITH_LABEL"
          :key="projectType.value"
          :to="{
            name: 'Project Index',
            query: {
              name: searchProjectName,
              type: projectType.value
            }
          }"
          class="project-search__examples__item"
          @click="scrollToTop()"
      >
        {{ projectType.label }}
      </router-link>
    </div>
  </div>
  <faqs-docs/>
</template>

<script>
import Flickity from "vue-flickity"
import ProjectCard from "../components/ProjectCard";
import FaqsDocs from "../components/FaqsDocs";
import Btn from "../components/ui/Btn";
import { mapState } from "vuex";
import { PROJECT_TYPES_WITH_LABEL } from "../constants/projects";
import { scrollToTop } from "../utils/domHelper";
import { getRecentProjectsForMainPageApi } from "../api/projects";
import NewsCard from "../components/NewsCard"
import NewsSlider from "../components/NewsSlider.vue"
import {getAboutUsApi, getCallToActionApi, getMainSliderApi} from "@/api/public";
import {getFileAsBlobUrl} from "@/api/files";

export default {
  name: "Home",
  setup() {
    return {
      flickityOptions: {
        cellAlign: 'left',
        contain: true,
        imagesLoaded: true,
        prevNextButtons: true,
        pageDots: true,
        lazyLoad: true,
        arrowShape: {
          x0: 10,
          x1: 60, y1: 50,
          x2: 65, y2: 45,
          x3: 20
        }
      },
      PROJECT_TYPES_WITH_LABEL
    }
  },
  data: () => ({
    searchProjectName: '',
    recentProjects: [],
    recentProjectsLoaded: false,

    loaded: false,

    callToActionItems: [],

    aboutUs: {},
    mainSlider: {}
  }),
  components: {Btn, FaqsDocs, ProjectCard, Flickity, NewsCard, NewsSlider},
  methods: {
    checkSlider(classname) {
      const slidersLength = document.querySelectorAll(`.${classname} .carousel-cell`).length
      return slidersLength > 1 ? 'flickity' : 'div'
    },
    scrollToTop,
    init(flickity) {
      if (flickity) {
        flickity.on('dragStart', () => flickity.slider.childNodes.forEach(slide => slide.style.pointerEvents = "none"));
        flickity.on('dragEnd', () => flickity.slider.childNodes.forEach(slide => slide.style.pointerEvents = "all"));
      }
    },

    toggleVideoControls(e) {
      if(e.type === 'mouseover') {
        e.srcElement.setAttribute('controls', true)
      }
      if(e.type === 'mouseleave') {
        e.srcElement.removeAttribute('controls')
      }
    }
  },
  computed: {
    ...mapState('lang', ['activeLang']),
    slider() {
      return {
        image: 'img/pomogat-prowe.png',
        title: this.$t('slider.title'),
        desc: this.$t('slider.desc'),
        collected: '38 150 000',
        bg: 'img/welcome_bg.png',
        bgColor: '#1B1715',
        bgOpacity: '.3',
        btnColor: '#FF7B01'
      }
    }
  },
  async created() {
    await getRecentProjectsForMainPageApi()
        .then(response => {
          this.recentProjects = response.data.content;
          this.recentProjectsLoaded = true;
        })

    this.callToActionItems = await getCallToActionApi();
    for (const item of this.callToActionItems) {
      item.imageFile = await getFileAsBlobUrl(item.backgroundImageId);
    }

    this.aboutUs = await getAboutUsApi();

    this.mainSlider = await getMainSliderApi();
    this.mainSlider.bgFile = await getFileAsBlobUrl(this.mainSlider.backgroundImageId);
/*
    this.mainSlider.videoFile = await getFileAsBlobUrl(this.mainSlider.videoId);
*/
    this.mainSlider.videoPosterFile = await getFileAsBlobUrl(this.mainSlider.videoPosterId);

    await this.$nextTick(() => {
      setTimeout(() => {
        this.loaded = true;
      }, 500)
    });
  }
}
</script>

<style scoped>

</style>