<template>
  <div class="admin-block">
    <page-title>
      <template v-slot:title>О нас</template>
    </page-title>
  </div>

  <div class="c-card" v-if="isLoaded">
    <div class="form">
      <div class="form-group form-group--full">
        <div class="form-label">Картинка для фона</div>
        <file-input
          v-model="payload.backgroundImageId"
          :handler="uploadFile"
          id="admin_slider_bg"
          :accept="ALLOWED_IMAGE_TYPES"
          :loading="uploadMediaRequestPending"
        />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Заголовок</div>
        <input
          class="form-input"
          type="text"
          v-model="titleWithActiveLang.text"
        />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Описание</div>
        <TextareInput
          class="form-input"
          v-model="descriptionWithActiveLang.text"
        />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Текст для кнопки</div>
        <input
          class="form-input"
          type="text"
          v-model="btnTextWithActiveLang.text"
        />
      </div>
      <div class="form-group form-group--full">
        <div class="form-label">Ссылка для кнопки</div>
        <input
          class="form-input"
          type="text"
          v-model="btnUrlsWithActiveLang.text"
        />
      </div>
      <div class="form-group form-group--full">
        <btn
          @click="submit"
          class="u-mt-2"
          color="green"
          height="48px"
          width="200px"
        >
          {{ $t('button.save') }}
        </btn>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from '../../../components/Layout/PageTitle'
import FileInput from '../../../components/ui/FileInput'
import TextareInput from '../../../components/ui/TextareaInput'
import Btn from '../../../components/ui/Btn'
import AboutUsApi from '../../../api/admin/about-us'
import { generateLocalesForProperties } from '../../../utils/dataManipulations'
import { uploadFileApi } from '../../../api/files'
import { ALLOWED_IMAGE_TYPES } from '../../../constants/file-types'
import { mapState } from 'vuex'

export default {
  name: 'AdminSlider',
  components: {
    PageTitle,
    FileInput,
    TextareInput,
    Btn,
  },

  data: () => ({
    ALLOWED_IMAGE_TYPES,
    uploadMediaRequestPending: false,
    isCreated: false,
    isLoaded: false,
    payload: {
      titles: [],
      descriptions: [],
      buttonTexts: [],
    },
  }),
  computed: {
    ...mapState('lang', ['adminActiveLang']),
    titleWithActiveLang() {
      return this.payload.titles.find(
        (title) => title.locale === this.adminActiveLang,
      )
    },
    descriptionWithActiveLang() {
      return this.payload.descriptions.find(
        (desc) => desc.locale === this.adminActiveLang,
      )
    },
    btnTextWithActiveLang() {
      return this.payload.buttonTexts.find(
        (btnText) => btnText.locale === this.adminActiveLang,
      )
    },
    btnUrlsWithActiveLang() {
      return this.payload.buttonUrls.find(
        (btnText) => btnText.locale === this.adminActiveLang,
      )
    },
  },
  methods: {
    submit() {
      if (this.isCreated) {
        AboutUsApi.update(this.payload).then(() => {
          this.$toast.success(this.$t('alert.success.updatedData'))
        })
      } else {
        AboutUsApi.create(this.payload).then(() => {
          this.$toast.success(this.$t('alert.success.updatedData'))
        })
      }
    },

    async uploadFile(file) {
      this.uploadMediaRequestPending = true
      return new Promise((resolve, reject) => {
        uploadFileApi(file)
          .then((response) => {
            const newFileID = response.data
            resolve(newFileID)
          })
          .catch((error) => {
            reject(error)
          })
          .finally(() => {
            this.uploadMediaRequestPending = false
          })
      })
    },
  },
  created() {
    this.payload.buttonTexts = generateLocalesForProperties({
      text: null,
    })
    this.payload.titles = generateLocalesForProperties({
      text: null,
    })
    this.payload.descriptions = generateLocalesForProperties({
      text: null,
    })
    this.payload.buttonUrls = generateLocalesForProperties({
      text: null,
    })
  },
  mounted() {
    AboutUsApi.get().then((res) => {
      if (res.createdDate) {
        this.isCreated = true
      }

      this.payload = {
        ...this.payload,
        backgroundColor: res.backgroundColor,
        backgroundImageId: res.backgroundImageId,
      }

      if (Array.isArray(res.titles) && res.titles.length) {
        this.payload.titles = res.titles
      }
      if (Array.isArray(res.descriptions) && res.descriptions.length) {
        this.payload.descriptions = res.descriptions
      }
      if (Array.isArray(res.buttonTexts) && res.buttonTexts.length) {
        this.payload.buttonTexts = res.buttonTexts
      }
      if (Array.isArray(res.buttonUrls) && res.buttonUrls.length) {
        this.payload.buttonUrls = res.buttonUrls
      }
      this.isLoaded = true
    })
  },
}
</script>
