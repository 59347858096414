<template>
  <div class="admin-block">
    <page-title>
      <template v-slot:title> {{ computedTitle }} </template>
    </page-title>
  </div>

  <div class="c-card">
    <news-form
      :handler="update"
      :predefined-data="predefinedData"
      v-if="isLoaded"
    />
  </div>
</template>

<script>
import NewsForm from '../../../components/workspace/NewsForm.vue'
import PageTitle from '../../../components/Layout/PageTitle'
import NewsApi from '../../../api/admin/news'
import { mapState } from 'vuex'

export default {
  name: 'AdminNewsCreate',
  components: {
    NewsForm,
    PageTitle,
  },
  data: () => ({
    predefinedData: {},
    isLoaded: false,
  }),
  computed: {
    ...mapState('lang', ['adminActiveLang']),
    computedTitle() {
      const findTitle = this.predefinedData.titles?.find(
        (item) => item.locale === this.adminActiveLang,
      )

      return findTitle ? findTitle.text : 'Новость'
    },
  },
  methods: {
    async update(payload) {
      return await NewsApi.update({
        ...payload,
        id: this.$route.params.id,
      }).then(() => {
        this.$toast.success(this.$t('alert.success.updatedData'))

        this.$router.push({
          name: 'Admin News Index',
        })
      })
    },
  },
  mounted() {
    NewsApi.get(this.$route.params.id).then((res) => {
      this.predefinedData = {
        slug: res.slug,
        mainMediaId: res.mainMediaId,
        videoPosterId: res.videoPosterId,
        titles: res.titles,
        descriptions: res.descriptions,
        contents: res.contents
      }

      this.isLoaded = true
    })
  },
}
</script>
