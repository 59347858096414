<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "WysiwygViewer"
})
</script>

<template>
  <div class="plain-content">
    <slot/>
  </div>
</template>

<style lang="scss">
.plain-content {

  h2, h3, h4, h5, h6 {
    margin-top: 30px;
    margin-bottom: 20px;
    &:first-child{
      margin-top: 0;
    }
  }

  h1 {
    font-size: 28px;
    margin-bottom: 30px;
  }

  p {
    margin: 10px 0;
    line-height: 1.6;
    &:first-child {
      margin-top: 0;
    }
  }

  ul, ol {
    margin: 15px 0;
    padding-left: 30px;

    li {
      font-weight: 500;
      margin: 3px;
    }
  }
}
</style>