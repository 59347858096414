import request from "./request";

export default {
  resource: "public/news",
  async getNews(params) {
    return await request.get(this.resource, {
      params
    })
  },
  async getSingle(id) {
    return await request.get(this.resource + `/${id}`)
  }
}
