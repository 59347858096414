import request from "./request";

const RESOURCE = 'public';

export async function getFaqsApi(limit = null) {
    const {data} = await request.get(`/${RESOURCE}/faq`, {
        params: {
            limit
        }
    });
    return data;
}

export async function getCategoriesApi(type) {
    const {data} = await request.get(`/${RESOURCE}/project-category`, {
        params: {
            type
        }
    });
    return data;
}

export async function getCallToActionApi() {
    const {data} = await request.get(`/${RESOURCE}/call-to-action`);
    return data;
}

export async function getAboutUsApi() {
    const {data} = await request.get(`/${RESOURCE}/about-us`);
    return data;
}

export async function getMainSliderApi() {
    const {data} = await request.get(`/${RESOURCE}/main-slider`);
    return data;
}

export async function getSuccessCardBySlugApi(slug) {
    const {data} = await request.get(`/${RESOURCE}/success-card?slug=${slug}`);
    return data[0];
}