<template>
  <div class="admin__news">
    <page-title>
      <template v-slot:title> Новости </template>

      <template v-slot:buttons>
        <btn
          :to="{
            name: 'Admin News Create',
          }"
          color="green"
          height="40px"
          width="160px"
        >
          {{ $t('button.create') }}
        </btn>
      </template>
    </page-title>

    <confirmation-dialog
      v-model="deleteDialog"
      :action="deleteNews"
      :title="
        $t('alert.confirm.deleting', {
          msg: itemForDelete.title,
        })
      "
    />

    <data-table
      :headers="headers"
      :items="computedData"
      :loading="!isLoaded"
    >
      <template v-slot:[`item.title`]="{ item }">
        {{ item.title }}
      </template>
      <template v-slot:[`item.description`]="{ item }">
        {{ item.description ? item.description.slice(0, 100) : null }}...
      </template>
      <template v-slot:[`item.actions`]="{ item, index }">
        <btn
          :to="{
            name: 'Admin News Single',
            params: {
              id: item.id,
            },
          }"
          color="black"
          small
        >
          {{ $t('button.more') }}
        </btn>
        <btn
          color="red"
          small
          @click="
            openDeleteDialog(item.id, item.title, index)
          "
        >
          {{ $t('button.delete') }}
        </btn>
      </template>
    </data-table>
  </div>
</template>

<script>
import PageTitle from '../../../components/Layout/PageTitle'
import DataTable from '../../../components/ui/DataTable/DataTable'
import Btn from '../../../components/ui/Btn/Btn'
import NewsApi from '../../../api/admin/news'
import { mapState } from 'vuex'
import ConfirmationDialog from '../../../components/Dialogs/ConfirmationDialog'

export default {
  name: 'AdminProjectNewIndex',
  components: {
    Btn,
    DataTable,
    PageTitle,
    ConfirmationDialog,
  },
  data: () => ({
    data: [],
    itemForDelete: {},
    deleteDialog: false,
    isLoaded: false,
  }),
  computed: {
    ...mapState('lang', ['adminActiveLang']),

    computedData() {
      return this.data.map((item) => {
        let findTitleLocale = item.titles.find(
          (title) => title.locale === this.adminActiveLang,
        )
        let findDescriptionLocale = item.descriptions.find(
          (desc) => desc.locale === this.adminActiveLang,
        )
        item.title = findTitleLocale
          ? findTitleLocale.text
          : ''
        item.description = findDescriptionLocale
          ? findDescriptionLocale.text
          : ''
        return item
      })
    },
    headers() {
      return [
        {
          name: this.$t('title.title'),
          value: 'title',
          sortable: false,
        },
        {
          name: this.$t('common.description'),
          value: 'description',
          sortable: false,
        },
        {
          name: '',
          value: 'actions',
          sortable: false,
        },
      ]
    },
  },
  methods: {
    async deleteNews() {
      await NewsApi.delete(this.itemForDelete.id)
      this.data.splice(this.itemForDelete.index, 1)
      this.deleteDialog = false
    },

    openDeleteDialog(id, title, index) {
      this.itemForDelete = {
        id,
        title,
        index,
      }
      this.deleteDialog = true
    },
  },
  mounted() {
    NewsApi.getAll().then((res) => {
      this.data = res
      this.isLoaded = true
    })
  },
}
</script>

<style lang="scss">
.admin__news {
  table {
    tbody {
      tr {
        td {
          &:last-child {
            display: flex;
            justify-content: flex-end;
            column-gap: 10px;
          }
        }
      }
    }
  }
}
</style>
