import {createI18n} from 'vue-i18n/index'
import {DEFAULT_LANGUAGE} from "./constants/languages";




let activeLang = localStorage.getItem('lang');
if (!activeLang) {
    localStorage.setItem('lang', DEFAULT_LANGUAGE);
    activeLang = DEFAULT_LANGUAGE;
}


let translations = loadTranslationsFromPath(activeLang);

export const i18n = createI18n({
    useScope: 'global',
    locale: activeLang,
    messages: {
        [activeLang]: translations
    }
})

export function updateLang(lang) {
    activeLang = lang
    translations = loadTranslationsFromPath(lang);
    i18n.global.locale = lang;
    i18n.global.fallbackLocale = lang
    i18n.global.messages[activeLang] = translations
}

export function loadTranslationsFromPath(activeLang) {
    const {default: data} = require(`./langs/${activeLang}.js`);

    return data;
}