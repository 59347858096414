<template>
  <div class="admin-block">
    <page-title>
      <template v-slot:title> {{ computedTitle }} </template>
    </page-title>
  </div>

  <div class="c-card" v-if="isLoaded">
    <call-to-action-form :handler="update" :predefined-data="predefinedData" />
  </div>
</template>

<script>
import CallToActionForm from '../../../components/workspace/CallToActionForm.vue'
import PageTitle from '../../../components/Layout/PageTitle'
import { generateLocalesForProperties } from '../../../utils/dataManipulations'
import CallToActionApi from '../../../api/admin/call-to-action'
import { mapState } from 'vuex'

export default {
  name: 'AdminCallToActionSingle',
  components: {
    CallToActionForm,
    PageTitle,
  },
  data: () => ({
    predefinedData: {},
    isLoaded: false,
  }),
  computed: {
    ...mapState('lang', ['adminActiveLang']),
    computedTitle() {
      const findTitle = this.predefinedData.titles?.find(
        (item) => item.locale === this.adminActiveLang,
      )

      return findTitle ? findTitle.text : 'Призыв карточки'
    },
  },
  methods: {
    async update(payload) {
      return await CallToActionApi.update({
        ...payload,
        id: +this.$route.params.id,
      }).then(() => {
        this.$toast.success(this.$t('alert.success.updatedData'))

        this.$router.push({
          name: 'AdminCallToActionIndex',
        })
      })
    },
  },
  mounted() {
    CallToActionApi.get(this.$route.params.id).then((res) => {
      this.predefinedData = {
        slug: res.slug,
        backgroundColor: res.backgroundColor,
        backgroundImageId: res.backgroundImageId,
        buttonUrl: res.buttonUrl,
        titles: res.titles.length
          ? res.titles
          : generateLocalesForProperties({
              text: null,
            }),
        descriptions: res.descriptions.length
          ? res.descriptions
          : generateLocalesForProperties({
              text: null,
            }),
        buttonTexts: res.buttonTexts.length
          ? res.buttonTexts
          : generateLocalesForProperties({
              text: null,
            }),
        buttonUrls: res.buttonUrls.length
          ? res.buttonUrls
          : generateLocalesForProperties({
              text: null,
            }),
      }

      this.isLoaded = true
    })
  },
}
</script>
