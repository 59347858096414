<template>
  <Dialog
      v-model="computedValue"
      class="payment-dialog"
      size="medium"
  >
    <template v-slot:header>
      <div class="modal__title">
        {{ actionForProject }}
      </div>
    </template>
    <template v-slot:body>
      <div v-show="activeStep === AMOUNT_STEP">
        <div class="form">
          <div class="form-group form-group--full">
            <label class="form-label">
              {{ $t('title.investmentAmount') }}
            </label>
            <amount-input v-model="payment.amount"/>
            <p class="form-group__hint">
              {{ $t('title.minInvestment') }}: {{project.minimalPayment}} {{ $t('sum') }}
            </p>
          </div>
          <div class="form-group" style="font-size: 12px; display: flex; align-items: center;">
            {{ $t('title.payments.moneyForFinish') }}
            <btn
              class="u-ml-2"
              sm
              style="min-width: 1px"
              color="grey"
              outlined
              height="28px"
              @click="payment.amount = remainingAmount"
            >
              {{makeFormattedNumber(remainingAmount)}} {{ $t('sum') }}
            </btn>
          </div>
          <div class="c-divider u-mb-2"/>
          <div class="form-group form-group--full">
            <user-bank-card-selector
              v-model="payment.cardId"
              ref="UserBankCardSelector"
            >
              <template #prepend-button>
                <div
                    class="form-group__hint"
                    v-if="!userIsAuthenticated"
                >
                  {{ $t('alert.info.unauthorizedUserCard') }}
                </div>
              </template>
            </user-bank-card-selector>
          </div>
        </div>


      </div>
      <div v-show="activeStep === CONTRACT_STEP">
        <back-btn
            @click="activeStep = AMOUNT_STEP"
        >
          {{ $t('button.goBack') }}
        </back-btn>
        <div class="payment-dialog__contract">
          <div class="plain-content">
            <h2>
              {{ $t('loanAgreement.title') }}
            </h2>
            <div
                class="u-mt-2"
                v-html="$t('loanAgreement.content', {
                  fio: project.owner,
                  passport: `${project.ownerPassportSeries}${project.ownerPassportNumber}`,
                  issuedBy: project.ownerIssuedBy,
                  issueDate: project.ownerDateOfIssue,
                  ownerAddress: project.ownerAddress,
                  rewardValue: project.rewardValue,
                  returnDate: getDateWithoutTZ(project.returnDate)
                })"
            />
          </div>
        </div>
        <checkbox
            id="acceptCheckbox"
            v-model="payment.accepted"
            class="u-mt-2 u-mb-2"
        >
          <template #label>
            {{ $t('agreement.offer') }}
          </template>
        </checkbox>
      </div>
      <div v-show="activeStep === SUCCESS_STEP">
        <success-block
            style="padding: 0;"
            :server-provider="projectTypeIsDonation ? 'success-donation' : 'success-investment'"
        />
      </div>
    </template>
    <template v-slot:buttons>
      <btn
          :loading="requestPending"
          v-show="activeStep === AMOUNT_STEP"
          class="modal__btn"
          color="green"
          @click="fillInfo()"
      >
        {{ $t('button.continue') }}
      </btn>
      <btn
          v-show="activeStep === CONTRACT_STEP"
          :loading="requestPending"
          class="modal__btn"
          color="green"
          @click="confirmPayment()"
      >
        {{ $t('button.confirm') }}
      </btn>
      <btn
          class="modal__btn"
          color="grey"
          outlined
          @click="closeModal()"
          v-show="activeStep !== SUCCESS_STEP"
      >
        {{ $t('button.cancel') }}
      </btn>

    </template>
  </Dialog>

</template>

<script>
import Dialog from "../ui/Dialog";
import Btn from "../ui/Btn";
import ModalDialogMixin from "../../mixins/ModalDialogMixin";
import UserBankCardSelector from "../UserBankCardSelector";
import AmountInput from "../ui/AmountInput";
import Checkbox from "../ui/Checkbox";
import BackBtn from "../ui/BackBtn";

import {payToProjectApi} from "../../api/payments";
import {PROJECT_TYPE_DONATION, PROJECT_TYPE_INVESTMENT} from "../../constants/projects";
import {mapGetters} from "vuex";
import {getDateWithoutTZ, makeFormattedNumber} from "../../utils/dataManipulations";
import SuccessBlock from "@/components/SuccessBlock/SuccessBlock.vue";

export default {
  name: "PaymentDialog",
  components: {SuccessBlock, BackBtn, Checkbox, AmountInput, UserBankCardSelector, Btn, Dialog},
  setup() {
    return {
      AMOUNT_STEP: 'AMOUNT_STEP',
      CONTRACT_STEP: 'CONTRACT_STEP',
      SUCCESS_STEP: 'SUCCESS_STEP',
      PROJECT_TYPE_DONATION,
      PROJECT_TYPE_INVESTMENT,
    }
  },
  data() {
    return {
      activeStep: this.AMOUNT_STEP,
      requestPending: false,
      userCards: [],
      payment: {
        accepted: false,
        amount: null,
        cardId: null,
      }
    }
  },
  mixins: [ModalDialogMixin],
  props: {
    actionForProject: String,
    project: {
      type: Object,
      default: () => {
        return {
          minimalPayment: null,
          id: 0,
          owner: '',
          ownerDateOfIssue: '',
          ownerIssuedBy: '',
          ownerPassportSeries: '',
          ownerPassportNumber: ''
        }
      }
    },
    projectType: {
      type: String,
      validator(val) {
        return val === PROJECT_TYPE_DONATION || val === PROJECT_TYPE_INVESTMENT;
      }
    },
  },
  computed: {
    ...mapGetters('auth', ['userIsAuthenticated']),
    remainingAmount() {
      return this.project.targetAmount - this.project.currentAmount;
    },
    projectTypeIsDonation(){
      return this.projectType === PROJECT_TYPE_DONATION
    }
  },
  methods: {
    getDateWithoutTZ,
    makeFormattedNumber,

    fillInfo() {
      let error = false;
      if(this.remainingAmount < this.payment.amount) {
        this.$toast.error("Осталось внести всего лишь " + makeFormattedNumber(this.remainingAmount) + " сум для окончания сборов, вы хотите внести " + makeFormattedNumber(this.payment.amount) + " сум");

        error = true;
      }
      if (!this.payment.amount) {
        this.$toast.error(this.$t('alert.error.enterInvestmentAmount'));
        error = true;
      }
      if (this.payment.amount < this.project.minimalPayment) {
        this.$toast.error(this.$t('alert.error.fewInvestmentAmount'));
        error = true;
      }
      if (!this.payment.cardId) {
        this.$toast.error(this.$t('title.chooseCard'));
        error = true;
      }
      if (error) return;

      if(this.projectTypeIsDonation){
        this.confirmPayment();
      }else{
        this.activeStep = this.CONTRACT_STEP;
      }

    },
    async confirmPayment() {
      if (!this.payment.accepted && !this.projectTypeIsDonation) {
        this.$toast.error(this.$t('alert.error.agreements'));
        return
      }

      this.requestPending = true;

      await payToProjectApi(this.project.id, this.payment.cardId, this.payment.amount)
          .then(() => {
            this.$emit('success', this.payment.amount);

            if(!this.userIsAuthenticated){
              this.$refs.UserBankCardSelector.removeCard(this.payment.cardId);
            }

            this.activeStep = this.SUCCESS_STEP;
          })
          .catch(error => {
            const errorCode = error.data.errorCode;
            if(errorCode === 'investition_not_allowed'){
              this.$toast.error(this.$t('alert.error.investmentDeclinedProject'))
            }else if(errorCode === 'wrong_user_payment_info'){
              this.$toast.error('Оплата не может быть выполнена выбранной картой');
            }else{
              this.$toast.error(this.$t('alert.error.unexpectedError.default'));
            }
          })
          .finally(() => {
            this.requestPending = false;
          });
    },
  },
  watch: {
    modelValue(val) {
      if (!val) this.activeStep = this.AMOUNT_STEP;
    }
  },
  async created() {
    this.payment.amount = this.project.minimalPayment;
  }
}
</script>

<style lang="scss">
.payment-dialog {
  &__contract {
    font-size: 12px;
    padding: 15px;
    background: #eee;
    border-radius: 4px;
    margin: 15px 0;
    max-height: calc(100vh - 400px);
    overflow-y: auto;
    @include scrollbar;

  }
}
</style>