<template>
  <div class="admin-block">
    <page-title>
      <template v-slot:title> Создание открытки </template>
    </page-title>
  </div>

  <div class="c-card">
    <success-cards-form :handler="create" />
  </div>
</template>

<script>
import SuccessCardsForm from '../../../components/workspace/SuccessCardsForm.vue'
import PageTitle from '../../../components/Layout/PageTitle'
import SuccessCardApi from '../../../api/admin/success-cards'

export default {
  name: 'AdminNewsCreate',
  components: {
    SuccessCardsForm,
    PageTitle,
  },
  methods: {
    async create(payload) {
      return await SuccessCardApi.create(payload).then(
        () => {
          this.$toast.success(
            this.$t('alert.success.addedCard'),
          )

          this.$router.push({
            name: 'Admin Success Cards Index',
          })
        },
      )
    },
  },
}
</script>
