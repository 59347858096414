<template>
  <div class="create-project" v-if="userIsAuthenticated">
    <div class="container">

      <bread-crumbs :links="breadCrumbs"/>

      <user-not-submitted v-if="!userIsSubmitted"/>

      <template v-else>
        <success-block
            server-provider="success-create-project"
            v-if="success"
        />

        <template v-else>
          <div class="create-project__title">
            {{ $t("button.createProject") }}
          </div>
          <single-project-management
              :handle-action-pending="requestPending"
              :handle-action-btn-text="$t('button.create')"
              @handleAction="createProject"
          />
        </template>
      </template>



    </div>
  </div>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import UserNotSubmitted from "../../components/UserNotSubmitted";
import SingleProjectManagement from "../../components/SingleProjectManagement";
import BreadCrumbs from "../../components/ui/BreadCrumbs";
import {createProjectApi} from "../../api/projects";
import SuccessBlock from "../../components/SuccessBlock/SuccessBlock";
import {scrollToTop} from "../../utils/domHelper";
import RedirectGuestMixin from '../../mixins/RedirectGuestMixin';

export default {
  name: "ProjectCreate",
  emits: ['handle-action'],
  mixins: [RedirectGuestMixin],
  components: {
    SuccessBlock,
    BreadCrumbs,
    UserNotSubmitted,
    SingleProjectManagement
  },
  data: () => ({
    success: false,
    requestPending: false
  }),
  computed: {
    ...mapGetters('auth', ['userIsSubmitted', 'userIsAuthenticated']),
    ...mapState('auth', ['user']),

    breadCrumbs() {
      return [
        {
          text: this.$t("nav.home"),
          disabled: false,
          href: {
            name: 'Home'
          }
        },
        {
          text: this.$t('nav.myProjects'),
          disabled: false,
          href: {
            name: 'Dashboard Projects'
          }
        },
        {
          text: this.$t("button.createProject"),
          disabled: true,
          href: ''
        }
      ]
    },
  },
  methods: {
    async createProject(project) {

      this.requestPending = true;

      createProjectApi(project)
          .then(() => {
            this.success = true;
            scrollToTop();
          })
          .catch(error => {
            console.log(error);
            this.$toast.error(this.$t('alert.error.onPublishing'));
          })
          .then(() => {
            this.requestPending = false;
          });
    },

  }
}
</script>